export const color = {
  primary: {
    alt: "",
    darkest: "",
    darker: "#CC202A",
    hover: "",
    base: "#DA303A",
    light: "",
    lighter: "",
    lightest: "",
  },
  secondary: {
    darkest: "",
    darker: "",
    hover: "",
    base: "",
    light: "",
    lighter: "",
    lightest: "",
  },
  gray: {
    alt: "",
    darkest: "#353535",
    darker: "#474747",
    dark: "#5F5F5F",
    base: "#A3A3A3",
    light: "#F5F5F5",
    lighter: "#F4F4F4",
    lightest: "",
    white: "#FFFFFF",
    black: "#000000",
  },
  body: { base: "#ffffff" },
  base: { base: "#0A0B09" },
  black: { base: "#0A0B09", alt: "#000000", lighter: "#1A1A1A" },
};
export const font = {
  family: {
    base: "Montserrat Webfont",
    head: "Campton Webfont",
  },
  base: "16px",
  small: "14px",
  xsmall: "12px",
  large: "18px",
  xlarge: "20px",
  xxlarge: "22px",
  xxxlarge: "24px",
};
