import styled from "styled-components";
import { color, font } from "../templates/ui";
import React from "react";

const StyledPrimaryButton = styled.button`
  position: relative;
  background-color: ${color.primary.base};
  transition: background-color 0.2s ease-in-out;
  text-align: left;
  border: 0;
  padding: 14px 70px 14px 14px;
  color: #fff;
  font-size: 16px;
  font-family: ${font.family.head};
  font-weight: 700;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);

  &:hover,
  &:focus {
    background-color: ${color.primary.darker};
    outline: none;
  }
`;

const StyledSecondaryButton = styled(StyledPrimaryButton)`
  transition: border-color 0.2s ease-in-out;
  border: 1px solid ${color.primary.base};
  background-color: transparent;

  &:hover,
  &:focus {
    border-color: ${color.primary.darker};
    background-color: transparent;
  }
`;

const StyledPrimaryIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 100%;
  background-color: ${color.primary.darker};

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient();
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledSecondaryIcon = styled(StyledPrimaryIcon)`
  background-color: transparent;
  border-left: 1px solid ${color.primary.base};
`;

const Button = ({
  className,
  variant = "primary",
  children,
  onClick,
  icon,
  onMouseDown,
  disabled,
}) => {
  if (variant === "secondary") {
    return (
      <StyledSecondaryButton
        className={className}
        onClick={onClick}
        onMouseDown={onMouseDown}
        disabled={disabled}
        type="submit"
      >
        {children}
        <StyledSecondaryIcon>{icon}</StyledSecondaryIcon>
      </StyledSecondaryButton>
    );
  }

  return (
    <StyledPrimaryButton
      className={className}
      onClick={onClick}
      onMouseDown={onMouseDown}
      disabled={disabled}
      type="submit"
    >
      {children}
      <StyledPrimaryIcon>{icon}</StyledPrimaryIcon>
    </StyledPrimaryButton>
  );
};

export default Button;
