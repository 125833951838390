import React from "react";
import styled from "styled-components";
import { space, color, layout } from "styled-system";

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1200px;
  
  ${space}
  ${color}
  ${layout}
`;

export default Container;
