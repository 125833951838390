import styled, { css } from "styled-components";
import { withDynamicTag } from "./DynamicTitle";
import { color } from "../templates/ui";
import DijSlash from "../assets/svg/dij-slash.svg";

const Title = styled.h1`
  position: relative;
  margin-left: ${(props) => (props.hasStripe ? "30px;" : "0")};
  color: ${(props) => {
    if (props.color === "white") {
      return `${color.body.base};`;
    }
    return `${color.black.base};`;
  }};

  ${(props) => {
    if (props.caps === true) {
      return `text-transform: uppercase;`;
    }

    if (props.hasLine === true) {
      return "padding-bottom: 10px; margin-bottom: 20px;";
    }
  }};

  &:before {
    ${(props) => {
      if (props.hasStripe === true) {
        return "content: ''";
      }
    }};

    position: absolute;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center center;
    ${(props) => {
      if (props.tag === "h1") {
        return css`background-image: url('${DijSlash}');height: 22px;width: 21px;top: 19px;left: -30px;`;
      }

      if (props.tag === "h2") {
        return css`background-image: url('${DijSlash}');height: 21px;width: 20px;top: 14px;left: -30px;`;
      }

      if (props.tag === "h3") {
        return css`background-image: url('${DijSlash}');height: 19px;width: 20px;top: 16px;left: -30px;`;
      }
      return css`background-image: url('${DijSlash}');height: 19px;width: 20px;top: 15px;left: -30px;`;
    }};

    @media only screen and (min-width: 991px) {
      ${(props) => {
        if (props.tag === "h1") {
          return "height: 31px;width: 31px;top: 23px;left: -46px;";
        }

        if (props.tag === "h2") {
          return "top: 18px;";
        }

        if (props.tag === "h3") {
          return "top: 16px;";
        }
        return "top: 15px;";
      }};
    }
  }

  &:after {
    ${(props) => {
      if (props.hasLine === true) {
        return "content: ''";
      }
    }};

    position: absolute;
    bottom: 0;
    left: 0;
    width: 86px;
    height: 4px;
    background-color: ${color.primary.base};
  }

  @media only screen and (min-width: 1240px) {
    margin-left: 0;
  }
`;

export default withDynamicTag(Title);
